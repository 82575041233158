<template>
  <div class="row">
    <div class="col mx-auto">
      <!-- <button class="btn btn-primary" v-if="!step" @click="step = 1">
              <i class="fad fa-calendar-plus mr-2"></i>
              Book an Appointment with {{ clinic.name }}
      </button>-->
      <template v-if="!paymentDetails">
        <client-details
          :clinic="clinic"
          v-if="currentStep == steps.clientDetails"
          @selected="selectedClient"
          :btn-bg-color="btnBgColor"
        ></client-details>

        <practitioner
          :clinic="clinic"
          :service="service"
          v-if="currentStep == steps.practitioner"
          @selected="selectedPractitioner"
          @selectedPackage="selectedPackage"
          :btn-bg-color="btnBgColor"
        ></practitioner>

        <service
          :clinic="clinic"
          :practitioner="practitioner"
          v-if="currentStep == steps.service"
          @selectedService="selectedService"
          @selectedPackage="selectedPackage"
          :btn-bg-color="btnBgColor"
        ></service>

        <service-method
          :client="client"
          :service="service"
          :practitioner="practitioner"
          v-if="currentStep == steps.serviceMethod && service"
          @selected="selectedMethod"
          :btn-bg-color="btnBgColor"
        ></service-method>
        <package-method
          :clinic="clinic"
          :client="client"
          :selectedPackage="packageSelected"
          :practitioner="practitioner"
          v-if="currentStep == steps.packageMethod && packageSelected"
          @selected="selectedMethod"
          :btn-bg-color="btnBgColor"
        ></package-method>

        <select-date
          :client="client"
          v-if="currentStep == steps.date"
          :practitioner="service ? practitioner : packageSelected.services[0].practitioner"
          :service="service ? service : packageSelected.services[0]"
          @selected="selectedDate"
          :btn-bg-color="btnBgColor"
        ></select-date>
        <select-time
          :client="client"
          v-if="currentStep == steps.time"
          :times="times"
          @selected="selectedTime"
          :btn-bg-color="btnBgColor"
        ></select-time>

        <service-confirm
          :client="client"
          v-if="currentStep == steps.serviceConfirm && service"
          :practitioner="practitioner"
          :service="service"
          :method="method"
          :date="date"
          :time="time"
          @startAgain="startAgain"
          :redirect="redirect"
          :btn-bg-color="btnBgColor"
        ></service-confirm>
        <package-confirm
          :client="client"
          v-if="currentStep == steps.packageConfirm && packageSelected"
          :practitioner="practitioner"
          :selected-package="packageSelected"
          :method="method"
          :date="date"
          :time="time"
          @startAgain="startAgain"
          :redirect="redirect"
          :btn-bg-color="btnBgColor"
        ></package-confirm>

        <button
          v-if="currentStep > 1"
          class="btn btn-light mt-4"
          @click="goBack"
        >
          Go back
        </button>
      </template>

      <!--  -->
    </div>
  </div>
</template>

<script>
import Practitioner from "./create-partials/Practitioner";
import Service from "./create-partials/Service";
import SelectDate from "./create-partials/Date";
import SelectTime from "./create-partials/Time";
import ClientDetails from "./create-partials/ClientDetails";
import ServiceMethod from "./create-partials/ServiceMethod";
import PackageMethod from "./create-partials/PackageMethod";
import ServiceConfirm from "./create-partials/ServiceConfirm";
import PackageConfirm from "./create-partials/PackageConfirm";

import { moment } from "moment";

export default {
  props: ["clinic", "redirect", "btnBgColor", "paymentDetails", "firstView"],
  data() {
    return {
      steps: {
        clientDetails: 1,
        practitioner: 2,
        service: 3,
        serviceMethod: 4,
        packageMethod: 4,
        date: 5,
        time: 6,
        serviceConfirm: 7,
        packageConfirm: 7,
      },
      currentStep: 1,
      client: null,
      practitioner: null,
      service: null,
      packageSelected: null,
      method: null,
      date: null,
      times: [],
      time: null,
    };
  },
  methods: {
    selectedClient(client) {
      this.client = client;
      this.currentStep++;
    },
    selectedPractitioner(practitioner, service) {
      this.practitioner = practitioner;
      if (service) {
        this.service = service;
      }
      this.currentStep++;
    },
    selectedService(service) {
      this.service = service;
      this.packageSelected = null;
      this.currentStep++;
    },
    selectedPackage(p) {
      this.packageSelected = p;
      this.currentStep+=2; //Skip two for packages - dont care about prac

    },
    selectedMethod(method) {
      this.method = method;
      this.currentStep++;
    },
    selectedDate(date, times) {
      this.times = times;
      this.date = date;
      this.currentStep++;
    },
    selectedTime(time) {
      this.time = time;
      this.currentStep++;
    },
    startAgain() {
      this.currentStep = 1;
      this.practitioner = null;
      this.service = null;
      this.date = null;
      this.times = [];
      this.time = null;
    },
    goBack() {
      if (this.currentStep === 3){
        this.startAgain();
      } else {
        this.currentStep--;
      }
    }
  },

  mounted() {
    if (this.firstView == "client") {
      this.steps = {
        clientDetails: 1,
        practitioner: 2,
        service: 3,
        serviceMethod: 4,
        packageMethod: 4,
        date: 5,
        time: 6,
        serviceConfirm: 7,
        packageConfirm: 7,
      };
    }

    if (this.firstView == "team") {
      this.steps = {
        practitioner: 1,
        service: 2,
        serviceMethod: 3,
        packageMethod: 3,
        date: 4,
        time: 5,
        clientDetails: 6,
        serviceConfirm: 7,
        packageConfirm: 7,
      };
    }

    if (this.firstView == "services") {
      this.steps = {
        service: 1,
        practitioner: 2,
        serviceMethod: 3,
        packageMethod: 3,
        date: 4,
        time: 5,
        clientDetails: 6,
        serviceConfirm: 7,
        packageConfirm: 7,
      };
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
  },
  components: {
    PackageMethod,
    ClientDetails,
    Practitioner,
    Service,
    ServiceMethod,
    SelectDate,
    SelectTime,
    ServiceConfirm,
    PackageConfirm,
  },
};
</script>

<style>
</style>
