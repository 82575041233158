<template>
  <div>
    <h4 class="mb-3">Please select your practitioner</h4>
    <hr />
    <busy v-if="busy"></busy>
    <div class="row" v-if="practitioners.length == 0">
      <div class="col">
        <p class="alert alert-info">
          There are no available practitioners to book with at this time.
        </p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-md-3 mb-3"
        v-for="practitioner in practitioners"
        :key="practitioner.id"
      >
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <img
              :src="practitioner.profile_pic_url"
              :alt="practitioner.name"
              class="rounded-circle"
              width="75"
              height="75"
            />
            <hr />
            <h5 class="mb-3 text-primary">{{ practitioner.name }}</h5>
            <button
              class="btn btn-outline-primary btn-sm"
              @click="selectPractitioner(practitioner)"
            >
              Select practitioner
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!service">
      <h4>Clinic Packages</h4>
      <div class="row">
        <div
            class="col-md-3"
            v-for="p in packages"
            :key="p.id"
            :value="p"
            @click="selectPackage(p)"
        >
          <div class="card border-0 shadow-sm">
            <img
                v-if="p.cover_image"
                :src="p.cover_image"
                class="card-img-top mx-auto"
                style="height: auto; width: 100%"
            />
            <img
                v-else
                :src="clinic.logo"
                class="card-img-top mx-auto p-4"
                style="height: auto; width: 100%"
            />
            <div class="card-body">
              <h5 class="">{{ p.name }}</h5>
              <h5>
              <span class="badge badge-primary" :style="buttonColors"
              >Package</span
              >
              </h5>
              <p>£{{ p.price }}</p>

              <button class="btn btn-sm btn-primary mt-3" :style="buttonColors">
                Select package
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";
import { moment } from "moment";
import axios from "axios";
export default {
  props: ["clinic", "service"],
  data() {
    return {
      practitioners: [],
      packages: [],
      busy: false,
    };
  },
  methods: {
    selectPractitioner(practitioner) {
      if (this.service) {
        this.fetchPractitionerService(practitioner);
      } else {
        this.$emit("selected", practitioner);
      }
    },
    selectPackage(p) {
      this.$emit("selectedPackage", p);
    },
    fetchPractitionerService(practitioner) {
      this.busy = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/fetch-practitioner-service/" +
            practitioner.id +
            "/" +
            this.service.id
        )
        .then(({ data }) => {
          this.$emit("selected", practitioner, data);
          this.busy = false;
        });
    },
    async fetchPackages() {
      await axios
          .get(
              process.env.VUE_APP_API_URL +
              "/api/front-end/appointments/fetch-clinic-packages/" +
              this.clinic
          )
          .then(({ data }) => {
            this.packages = data;
          });
    },
    async fetchPractitioners() {
      this.busy = true;
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/" +
            this.clinic +
            "/fetch-practitioners"
        )
        .then(({ data }) => {
          this.practitioners = data;
          /*
          TODO: Added below workaround but needs a rework to entire steps workflow. 
          */
          if (this.packages.length < 1 && this.practitioners.length == 1) {
            this.selectPractitioner(this.practitioners[0]);
          }
          this.busy = false;
        });
    },
  },
  mounted() {
    this.fetchPackages().then(() => {
      this.fetchPractitioners();
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>
