<template>
  <div>
    <h4 class="mb-3" v-if="practitioner">
      Choose the service you would like with {{ practitioner.name }}
    </h4>
    <hr />
    <busy v-if="busy"></busy>
    <div class="row">
      <div
        class="col-md-4"
        v-for="s in services"
        :key="s.id"
        :value="s"
        @click="selectService(s)"
      >
        <div class="card border-0 shadow-sm">
          <img
            v-if="s.cover_image"
            :src="s.cover_image"
            class="card-img-top mx-auto"
            style="height: auto; width: 100%"
          />
          <img
            v-else
            :src="clinic.logo"
            class="card-img-top mx-auto p-4"
            style="height: auto; width: 100%"
          />
          <div class="card-body">
            <h5 class="">{{ s.name }}</h5>
            <p>{{ s.duration ? s.duration : 30 }} mins</p>
            <p>£{{ s.price }}</p>

            <button class="btn btn-sm btn-primary mt-3" :style="buttonColors">
              Select service
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-md-4"
        v-for="p in packages"
        :key="p.id"
        :value="p"
        @click="selectPackage(p)"
      >
        <div class="card border-0 shadow-sm">
          <img
            v-if="p.cover_image"
            :src="p.cover_image"
            class="card-img-top mx-auto"
            style="height: auto; width: 100%"
          />
          <img
            v-else
            :src="clinic.logo"
            class="card-img-top mx-auto p-4"
            style="height: auto; width: 100%"
          />
          <div class="card-body">
            <h5 class="">{{ p.name }}</h5>
            <h5>
              <span class="badge badge-primary" :style="buttonColors"
                >Package</span
              >
            </h5>
            <p>£{{ p.price }}</p>

            <button class="btn btn-sm btn-primary mt-3" :style="buttonColors">
              Select package
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";
import { moment } from "moment";
import axios from "axios";

export default {
  props: ["clinic", "practitioner", "btnBgColor"],
  data() {
    return {
      services: [],
      packages: [],
      busy: false,
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    selectService(service) {
      this.$emit("selectedService", service);
    },
    selectPackage(p) {
      this.$emit("selectedPackage", p);
    },
    fetchServices() {
      this.busy = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/fetch-services/" +
            this.practitioner.id
        )
        .then(({ data }) => {
          this.services = data;
          this.busy = false;
        });
    },
    fetchServicesNoPractitioner() {
      this.busy = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/fetch-clinic-services/" +
            this.clinic
        )
        .then(({ data }) => {
          this.services = data;
          this.busy = false;
        });
    },
    fetchPackages() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/fetch-clinic-packages/" +
            this.clinic
        )
        .then(({ data }) => {
          this.packages = data;
        });
    },
  },
  mounted() {
    this.fetchPackages();
    if (this.practitioner) {
      this.fetchServices();
    } else {
      this.fetchServicesNoPractitioner();
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>
