<template>
  <div>
    <div class="row">
      <div class="col">
        <h5 class="mb-4" v-if="!confirmed">
          Please confirm your appointment details
        </h5>
        <h5 class="mb-4" v-if="confirmed">
          Your appointment has been requested
        </h5>

        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Selected Date</div>
              <div class="col">{{ date | formatDate }}</div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Selected Time</div>
              <div class="col">{{ time | formatTime }}</div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Selected Service</div>
              <div class="col">{{ service.name }}</div>
            </div>
          </li>

          <li class="list-group-item" v-if="service.price > 0">
            <div class="row">
              <div class="col-12 col-md-4">Selected Service Price</div>
              <div class="col">£{{ service.price }}</div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Service Duration</div>
              <div class="col">
                {{ service.duration ? service.duration : 30 }} mins
              </div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Service Method</div>
              <div class="col text-capitalize">{{ method }}</div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="row">
              <div class="col-12 col-md-4">Selected Practitioner</div>
              <div class="col">{{ practitioner.name }}</div>
            </div>
          </li>
        </ul>

        <div class="row mb-4" v-if="!confirmed">
          <div class="col-auto ml-auto">
            <button
              class="btn btn-primary"
              :disabled="busy"
              @click="bookAppointment"
              :style="buttonColors"
            >
              Book Appointment
            </button>
          </div>
        </div>

        <div class="row" v-if="busy">
          <div class="col text-center">
            <div class="d-block mb-3">
              <img src="@/assets/spinner.gif" alt="" width="100px" />
            </div>
            <h5 class="mb-4">Processing your booking details</h5>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="alert alert-info">
              A payment of <strong>£{{ service.price }}</strong> is due for your
              selected service, upon clicking "Book Appointment" you will be
              redirected to our secure payments page.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  props: [
    "practitioner",
    "service",
    "method",
    "date",
    "time",
    "client",
    "redirect",
    "btnBgColor",
  ],
  data() {
    return {
      confirmed: false,
      busy: false,
    };
  },
  computed: {
    datetime() {
      return moment(this.date + " " + this.time, "YYYY-MM-DD HH:mm:ss")
        .utc()
        .toISOString();
    },
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    bookAppointment() {
      this.busy = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/api/front-end/appointments", {
          client: this.client.id,
          practitioner: this.practitioner.id,
          service: this.service.id,
          method: this.method,
          datetime: this.datetime,
        })
        .then(({ data }) => {
          console.log(data);
          this.confirmed = true;
          this.busy = false;

          if (data.consultation.order) {
            window.location.href =
              process.env.VUE_APP_FRONTEND_URL +
              "/consultations/payment/" +
              data.consultation.id +
              "?redirect=" +
              encodeURIComponent(window.location);
          }

          if (this.redirect) {
            window.location.href = this.redirect;
          }
        });
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      var noSeconds = time.substr(0, time.lastIndexOf(":"));
      if (noSeconds > "12:00") {
        return noSeconds + " pm";
      } else {
        return noSeconds + " am";
      }
    },
  },
};
</script>

<style>
</style>