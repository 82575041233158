<template>
  <div>
    <div>
      <div class="row mb-4">
        <div class="col-auto">
          <div class="card border-0 bg-light">
            <div class="card-body">
              <h3 class="mb-0">Total from scored sections: {{ totalScore }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sections"],
  computed: {
    totalScore() {
      var score = 0;
      this.sections.forEach((s) => {
        if (s.scored) {
          score = score + +s.score;
        }
      });

      return score;
    },
  },
  watch: {
    totalScore(val, oldVal) {
      if (val > 0) {
        this.$parent.totalFormScore = this.totalScore;
      }
    },
  },
  methods: {
    generateScore() {},
  },
};
</script>

<style>
</style>