<template>
  <div class="row text-left">
    <div class="col">
      <busy v-if="busy"></busy>
      <form @submit.prevent="addClient" v-show="!busy">
        <div class="row mb-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <label for>First Name</label>
            <input
              type="text"
              v-model="form.first_name"
              class="form-control"
              placeholder="Jane"
              required
            />
          </div>

          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <label for>Last Name</label>
            <input
              type="text"
              v-model="form.last_name"
              class="form-control"
              placeholder="Bloggs"
              required
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <label for>Email Address</label>
            <input
              type="text"
              v-model="form.email"
              class="form-control"
              placeholder="email@example.com"
              required
            />
          </div>

          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <label for>Phone Number</label>
            <input
              type="text"
              v-model="form.phone"
              class="form-control"
              placeholder="+44123456789"
              required
            />
          </div>
        </div>

        <div class="row">
          <div class="col-auto ml-auto">
            <button type="submit" class="btn btn-primary" :style="buttonColors">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Busy from "./Busy";

export default {
  props: ["clinic", "btnBgColor"],
  data() {
    return {
      busy: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        clinic_id: this.clinic,
      },
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    addClient() {
      this.busy = true;
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/add-client",
          this.form
        )
        .then(({ data }) => {
          this.$emit("selected", data.client);
          this.busy = false;
        });
    },
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>