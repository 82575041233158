<template>
  <div>
    <div class="row text-center my-5">
      <div class="col">
        <img src="@/assets/spinner.gif" alt="" width="100px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>