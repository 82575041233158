<template>
  <details>
    <summary>
      <h4 class="font-weight-bold d-inline-flex">{{ section.title }}</h4>
    </summary>

    <div>
      <template v-for="f in section.client_response">
        <div class="row mb-3" :key="f.name">
          <div class="col mx-auto">
            <template v-if="f.type == 'date' || f.type == 'text'">
              <label v-if="f.label" :for="f.name" v-html="f.label"></label>

              <input
                :type="f.type"
                :name="f.name"
                :id="f.name"
                :class="f.className"
                v-model="f.userData[0]"
              />
            </template>

             <template v-if="f.type == 'textarea'">
              <label v-if="f.label" :for="f.name" v-html="f.label"></label>

              <textarea
                :name="f.name"
                :id="f.name"
                :class="f.className"
                v-model="f.userData[0]"
              />
            </template>

            <template v-if="f.type == 'number'">
              <label v-if="f.label" :for="f.name" v-html="f.label"></label>

              <input
                :type="f.type"
                :name="f.name"
                :id="f.name"
                @change="updateSectionScore"
                :class="f.className"
                v-model="f.userData[0]"
              />
            </template>

            <template v-if="f.type == 'radio-group'">
              <div v-html="f.label"></div>
              <template v-for="v in f.values">
                <div
                  :key="'radio-group' + v.value"
                  class="form-check"
                  :class="f.inline ? 'form-check-inline' : ''"
                >
                  <input
                    type="radio"
                    :name="f.name"
                    :id="f.name + '-' + v.value"
                    :value="v.value"
                    class="form-check-input"
                    :class="f.inline ? '' : ''"
                    @change="updateSectionScore"
                    v-model="f.userData"
                  />
                  <label :for="f.name + '-' + v.value">{{ v.label }}</label>
                </div>
              </template>
            </template>

            <template v-if="f.type == 'select'">
              <select
                :name="f.name"
                :id="f.name + '-' + v.value"
                :class="f.className"
                v-model="f.userData"
              >
                <option
                  v-for="v in f.values"
                  :key="'select' + v.value"
                  :value="v.value"
                ></option>
              </select>
            </template>

            <template v-if="f.type == 'header'">
              <div v-html="f.label"></div>
            </template>

            <template v-if="f.type == 'paragraph'">
              <div v-html="f.label"></div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </details>
</template>

<script>
export default {
  props: ["section"],
  filters: {
    stripTags(string) {
      return string.replace(/(<([^>]+)>)/gi, "");
    },
  },
  methods: {
    updateSectionScore() {
      if (this.section.scored) {
        this.section.score = 0;
        this.section.client_response.forEach((field) => {
          if (field.type === "number") {
            if (field.userData && parseInt(field.userData[0]) > 0) {
              this.section.score =
                this.section.score + parseInt(field.userData[0]);
            }
          }
          if (field.type === "radio-group") {
            if (field.userData && parseInt(field.userData[0])){
              this.section.score = this.section.score + parseInt(field.userData[0]);
              console.log(this.section.score);
            }
          }
        });
      }
    },
  },
};
</script>

<style>
summary:focus {
  outline: none;
}
summary::-webkit-details-marker {
  display: none;
}

summary > div {
  width: calc(100% - 50px);
  display: flex;
  vertical-align: middle;
}

details {
  font-size: 20px;
}

summary {
  display: block;
}

summary:before {
  content: "►";
  margin: 0px 10px 0 0;
  width: 20px;
}

details[open] summary {
  margin-bottom: 2rem;
}

details[open] summary:before {
  content: "▼";
}
</style>