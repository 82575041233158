<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col">
                <h5>Consultation Details</h5>
                <p class="">
                  <strong>{{
                    consultation.package
                      ? consultation.package.name
                      : consultation.service.name
                  }}</strong>
                  has been booked with
                  <strong>{{ consultation.order.clinic.name }}</strong
                  >, your selected practitioner,
                  <strong>{{ consultation.practitioner.name }}</strong> has been
                  notified of your appointment on
                  <strong>{{ consultation.start | formatDateTime }}</strong>
                </p>
              </div>
            </div>
            <!--  -->

            <!--  Paid -->
            <div class="row" v-if="paid">
              <div class="col">
                <p class="alert alert-info">
                  Your payment has been received
                </p>
              </div>
            </div>

            <!-- Not Paid -->

            <div class="row" v-else>
              <div class="col">
                <p class="alert alert-warning mb-4">
                  Your payment has not been received, please try again
                </p>
                <button
                  class="btn btn-primary float-right"
                  :style="buttonColors"
                  @click="tryPaymentAgain"
                >
                  Attempt payment again
                </button>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  props: ["consultation", "btnBgColor"],
  data() {
    return {
      paid: false,
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
    consultData: {
      get() {
        return this.consultation;
      },
      set(data) {
        return data;
      },
    },
    client() {
      return this.consultData.client;
    },
    datetime() {
      return moment(this.date + " " + this.time, "YYYY-MM-DD HH:mm:ss")
        .utc()
        .toISOString();
    },
  },
  methods: {
    confirmConsultation() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/confirm/" +
            this.consultData.id
        )
        .then(({ data }) => {
          console.log(data);
        });
    },
    tryPaymentAgain() {
      window.location.href = window.location.href =
        process.env.VUE_APP_FRONTEND_URL +
        "/consultations/payment/" +
        this.consultData.id +
        "?redirect=" +
        encodeURIComponent(window.location);
    },
  },
  mounted() {
    if (this.consultData.paymentStatus == "paid" || this.consultData.paymentStatus == "partial") {
      this.paid = true;
      this.confirmConsultation();
    } else {
      // Poll to check it has been paid
      var intervalId = setInterval(() => {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/api/front-end/appointments/check-payment/" +
              this.consultData.id
          )
          .then(({ data }) => {
            if (data.paymentStatus == "paid" || data.paymentStatus == "partial") {
              this.paid = true;
              clearInterval(intervalId);
            }
          });
      }, 1000);
    }
  },
  filters: {
    formatDateTime(date) {
      return moment.utc(date).local().format("LLLL");
    },
  },
};
</script>

<style>
</style>