<template>
  <div>
    <h4 class="mb-3">Please select a suitable time</h4>
    <hr />
    <div class="row">
      <div class="col-12 col-md-3 mb-3" v-for="(time, key) in times" :key="key">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <h5 class="mb-3">{{ key | formatTime }}</h5>
            <button
              class="btn btn-primary btn-sm"
              :style="buttonColors"
              @click="selectTime(key)"
            >
              Select time
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["times", "btnBgColor"],
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    selectTime(time) {
      this.$emit("selected", time);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      var noSeconds = time.substr(0, time.lastIndexOf(":"));
      if (noSeconds >= "12:00") {
        return noSeconds + "pm";
      } else {
        return noSeconds + "am";
      }
    },
  },
};
</script>

<style>
</style>