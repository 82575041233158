<template>
  <div
    class="swandoola-appointment-booker py-4"
    :class="background ? 'bg-light' : ''"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <busy v-if="busy"></busy>
          <create-appointment
            v-if="!busy"
            :clinic="clinic"
            :redirect="redirect"
            :btn-bg-color="btnBgColor"
            :payment-details="consultation"
            :first-view="firstView"
          ></create-appointment>

          <payment-details
            v-if="consultation && !busy"
            :clinic="clinic"
            :consultation="consultation"
            :btn-bg-color="btnBgColor"
          ></payment-details>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.css";

import Busy from "./create/create-partials/Busy";
import CreateAppointment from "./create/CreateAppointment";
import PaymentDetails from "./payments/PaymentDetails";
import axios from "axios";

export default {
  props: ["clinic", "redirect", "btnBgColor", "background", "firstView"],
  name: "AppointmentBooker",
  data() {
    return {
      consultation: null,
      busy: false,
    };
  },
  methods: {
    checkPaymentStatus(consultationId) {
      this.busy = true;
      console.log("detected a consultation");
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/check-payment/" +
            consultationId
        )
        .then(({ data }) => {
          console.log(data);
          this.consultation = data;
          this.busy = false;
        });
    },
  },
  mounted() {
    console.log("loading the appointment booker");
    console.log("The clinic ID is - " + this.clinic);

    // Check for a redirect from the swandoola app
    const params = new URLSearchParams(window.location.search);
    if (params.has("consultation")) {
      this.checkPaymentStatus(params.get("consultation"));
    }
  },
  components: {
    CreateAppointment,
    PaymentDetails,
    Busy,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@import "~bootstrap/scss/bootstrap";
create-appointment,
payment-details {
  font-family: "Montserrat", sans-serif !important;
}
</style>