<template>
  <div>
    <h4 class="mb-3">
      Choose the date you would like your {{ service.name }} to be on
    </h4>
    <hr />
    <busy v-if="busy"></busy>
    <div class="row">
      <div
        class="col-12 col-md-3 mb-3"
        v-for="(times, key) in dates"
        :key="key"
      >
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body text-center">
            <h5 class="mb-3">{{ key | formatDate }}</h5>
            <button
              class="btn btn-primary btn-sm"
              :style="buttonColors"
              @click="selectDate(key, times)"
            >
              Select date
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";
import moment from "moment";
import axios from "axios";
export default {
  props: ["client", "practitioner", "service", "btnBgColor"],
  data() {
    return {
      dates: [],
      busy: false,
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    selectDate(date, times) {
      this.$emit("selected", date, times);
    },
    fetchDates() {
      this.busy = true;
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/front-end/appointments/fetch-dates",
          {
            practitioner_id: this.practitioner.id,
            practitioner_service_id: this.service.id,
          }
        )
        .then(({ data }) => {
          this.dates = data;
          this.busy = false;
        });
    },
  },
  mounted() {
    this.fetchDates();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>