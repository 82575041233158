<template>
  <div class="card border-0 shadow-sm mb-5">
    <form
      class="card-body"
      :ref="'form-render-' + section.id"
      @change="saveResponse(section)"
      @submit.prevent="saveResponse(section)"
    >
      <inputs :section="section"></inputs>
      <div v-if="section.scored">
        <h5 class="font-weight-bold">
          Section Score Total: {{ section.score }}
        </h5>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import Inputs from "./Inputs";

export default {
  props: ["section", "clientForm"],

  methods: {
    saveResponse(section) {
      // var form = this.$refs["form-render-" + section.id];

      //       var responseObjectForSubmission = Object.values(form).reduce((obj,field) => { obj[field.name] = field.value; return obj }, {})
      // console.log(responseObjectForSubmission)
      if (this.clientForm.status !== "response_received") {
        // const renderer = this.sectionRenderers.filter((renderer) => {
        //   return renderer.section_id === section.id;
        // })[0].renderer;

        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/front-end/forms/" +
              this.clientForm.id +
              "/response",
            {
              client_form_section_id: section.id,
              form_data: section.client_response,
              score: section.score,
            }
          )
          .then(({ data }) => {
            if (this.clientForm.status === "sent") {
              this.clientForm.status = "in_progress";
            }
            // Bus.$emit("alert", data);

            console.log("alert", data);
          });
      } else {
        // Bus.$emit("alert", {
        //   color: "danger",
        //   message: "Response already sent. Unable to save changes.",
        // });

        console.log("alert", {
          color: "danger",
          message: "Response already sent. Unable to save changes.",
        });
      }
    },
  },
  components: {
    Inputs,
  },
};
</script>

<style>
</style>