<template>
  <div class="">
    <form @submit.prevent="addClient">
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="">First name</label>
          <input type="text" v-model="form.first_name" class="form-control" />
        </div>
        <div class="col-md-6 mb-3">
          <label for="">Last name</label>
          <input type="text" v-model="form.last_name" class="form-control" />
        </div>
      </div>

      <!--  -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="">Email address</label>
          <input type="email" v-model="form.email" class="form-control" />
        </div>
        <div class="col-md-6 mb-3">
          <label for="">Telephone</label>
          <input type="number" v-model="form.phone" class="form-control" />
        </div>
      </div>

      <!--  -->

      <div class="row">
        <div class="col-auto ml-auto">
          <button class="btn btn-primary">Start health form</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: ["clinic", "formId"],
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: "",
        form_id: this.formId,
        clinic_id: this.clinic,
      },
    };
  },
  methods: {
    addClient() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/front-end/forms/clients",
          this.form
        )
        .then(({ data }) => {
          console.log(data);
          this.$emit("clientSetFormReceived", data.client, data.form);
        });
    },
  },
};
</script>

<style>
</style>