<template>
  <div>
    <h4 class="mb-3">
      Select a method for your {{ service.name }} with {{ practitioner.name }}
    </h4>
    <hr />
    <busy v-if="busy"></busy>

    <div class="row">
      <div class="col-md-8 mx-auto">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item cursor-pointer py-5"
            v-if="service.service.allow_standard"
          >
            <div class="row">
              <div class="col-12 col-md my-auto">Standard Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button
                  class="btn btn-sm btn-primary mt-3 mt-md-0"
                  :style="buttonColors"
                  @click="selectMethod('standard')"
                >
                  Select consult method
                </button>
              </div>
            </div>
          </li>

          <li
            class="list-group-item cursor-pointer py-5"
            v-if="service.service.allow_video"
          >
            <div class="row">
              <div class="col-12 col-md my-auto">Video Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button
                  class="btn btn-sm btn-primary mt-3 mt-md-0"
                  :style="buttonColors"
                  @click="selectMethod('video')"
                >
                  Select consult method
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";
import { moment } from "moment";

export default {
  props: ["client", "service", "practitioner", "btnBgColor"],
  data() {
    return {
      services: [],
      busy: false,
    };
  },
  computed: {
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    selectMethod(method) {
      this.$emit("selected", method);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatTime(time) {
      return time.substr(0, time.lastIndexOf(":"));
    },
  },
  components: {
    Busy,
  },
};
</script>

<style>
</style>