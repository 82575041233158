<template>
  <div class="container">
    <template v-if="clinic && formId">
      <client-details
        v-if="!client"
        :clinic="clinic"
        :form-id="formId"
        @clientSetFormReceived="clientSetFormReceived"
      ></client-details>

      <form-renderer
        v-if="client"
        :client="client"
        :clinic-id="clinic"
        :form="form"
        :form-type="formType"
        :follow-up-mail-id="followUpMailId"
        :email-series-id="emailSeriesId"
        :scored-image="scoredImage"
      ></form-renderer>
    </template>
    <template v-else>
      <div class="row">
        <div class="col">
          <p class="alert alert-danger">
            This snippet has not been set up correctly, please contact Swandoola
            support
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
require("bootstrap");

import ClientDetails from "./health-form-partials/ClientDetails";
import FormRenderer from "./health-form-partials/FormRenderer";

export default {
  props: {
    clinic: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
    followUpMailId: {
      type: String,
      default: "",
    },
    emailSeriesId: {
      type: String,
      default: "",
    },
    scoredImage: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "standard", // can be standard or scored
    },
  },
  data() {
    return {
      client: null,
      form: null,
    };
  },
  methods: {
    clientSetFormReceived(client, form) {
      this.client = client;
      this.form = form;
    },
  },
  filters: {
    formatDate(date) {
      return this.$moment(date).format("LL");
    },
  },
  components: {
    ClientDetails,
    FormRenderer,
  },
};
</script>


<style lang="scss">
@import "~bootstrap";
</style>