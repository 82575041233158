<template>
  <div>
    <h4 class="mb-3">
      <span v-if="!confirmPackage">Package Information: {{ selectedPackage.name }}</span>
      <span v-else>{{ selectedPackage.name }} - {{firstService.name}}</span>
    </h4>
    <hr />
    <busy v-if="busy"></busy>

    <div class="row" v-if="firstService && confirmPackage">
      <div class="col-md-8 mx-auto">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item cursor-pointer py-5"
            v-if="firstService.practitioner_service.service.allow_standard"
          >
            <div class="row">
              <div class="col-12 col-md my-auto">Standard Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button
                  class="btn btn-sm btn-primary mt-3 mt-md-0"
                  :style="buttonColors"
                  @click="selectMethod('standard')"
                >
                  Select consult method
                </button>
              </div>
            </div>
          </li>

          <li
            class="list-group-item cursor-pointer py-5"
            v-if="firstService.practitioner_service.service.allow_video"
          >
            <div class="row">
              <div class="col-12 col-md my-auto">Video Consultation</div>
              <div class="col-auto my-auto ml-auto">
                <button
                  class="btn btn-sm btn-primary mt-3 mt-md-0"
                  :style="buttonColors"
                  @click="selectMethod('video')"
                >
                  Select consult method
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-6 mb-3" v-for="(s, index) in selectedPackage.services" :key="s.id">
          <div class="card border-0 shadow-sm">
            <div class="card card-header " :class="index === 0 ? 'bg-primary text-white' : ''">Service: {{ s.name }}</div>
            <div class="card-body">
              <p>{{ s.practitioner.name }}</p>
              <p>{{ s.practitioner_service.duration ? s.practitioner_service.duration : 30 }} mins</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <button class=" btn btn-sm btn-primary mt-3" @click="confirmPackage = true">
                Continue
              </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "./Busy";

export default {
  props: ["clinic", "client", "selectedPackage", "practitioner", "btnBgColor"],
  data() {
    return {
      busy: false,
      confirmPackage: false,
    };
  },
  computed: {
    firstService() {
      return this.selectedPackage.services.length > 0
        ? this.selectedPackage.services[0]
        : null;
    },
    buttonColors() {
      return (
        "background-color: " +
        this.btnBgColor +
        "; border-color: " +
        this.btnBgColor
      );
    },
  },
  methods: {
    selectMethod(method) {
      this.$emit("selected", method);
    },
  },
  filters: {},
  components: {
    Busy,
  },
};
</script>

<style>
</style>
