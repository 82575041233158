<template>
  <div v-if="clientForm">
    <div class="row mb-3">
      <div class="col my-auto">
        <h3 class="font-weight-bold">{{ clientForm.title }}</h3>
      </div>
    </div>

    <template v-if="clientForm.status === 'response_received'">
      <div class="row mb-2">
        <div class="col">
          <div v-if="formType === 'scored'">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <p class="mb-4">
                  Congratulations on taking your free health assessment and
                  steps towards your best health! <br />
                  You scored <b>{{ totalFormScore }}</b> <br />The Medical Symptoms
                  Questionnaire (MSQ) score is an essential functional medicine
                  tool to assess patients symptoms initially and then to monitor
                  your progress. The higher your symptom score, the more severe
                  your medical condition.
                </p>
                <h5>A total score of:</h5>
                <ul class="list mb-4">
                  <li class="list-item">
                    20 or less is not clinically significant
                  </li>
                  <li class="list-item">20 to 49 indicates mild toxicity</li>
                  <li class="list-item">
                    50 to 99 indicates moderate toxicity
                  </li>
                  <li class="list-item">
                    100 and above indicates severe toxicity
                  </li>
                </ul>

                <img
                  v-if="scoredImage"
                  :src="scoredImage"
                  alt=""
                  style="width: 100%"
                  class="mb-4"
                />

                <p>
                  A member of our team will be in touch shortly to discuss your
                  results further.
                </p>
              </div>
            </div>
          </div>

          <p class="alert alert-success text-center">
            Thanks for completing this form, your response was sent:
            {{ this.clientForm.client_response_date | formatDate }}
          </p>
        </div>
      </div>
    </template>

    <template v-if="clientForm.status != 'response_received'">
      <div class="row mb-3">
        <div class="col">
          <p class="alert alert-primary mb-3 mt-1">
            Please complete the following form sections. You can expand each
            section by clicking on the title.
            <span v-if="formType === 'scored'">
              <br />
              Each section may contain number fields, once you have completed
              all sections click on generate my score.
            </span>
          </p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <form-section
            v-for="section in sections"
            :key="section.id"
            :section="section"
            :client-form="clientForm"
          ></form-section>

          <score v-if="formHasScoredSections" :sections="sections"></score>
        </div>
      </div>

      <div class="row" v-if="clientForm.status === 'in_progress'">
        <div class="col-auto ml-auto text-right">
          <button
            class="btn btn-primary btn-lg"
            @click="sendResponse"
            :disabled="busySending"
          >
            <i class="fad fa-share mr-1" v-if="!busySending"></i>
            <i class="fad fa-spinner fa-spin mr-1" v-else></i>
            Submit & Complete
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import FormSection from "./sections/FormSection";
import Score from "./Score";

export default {
  props: [
    "clinicId",
    "client",
    "form",
    "formType",
    "followUpMailId",
    "emailSeriesId",
    "scoredImage",
  ],
  data() {
    return {
      clientForm: null,
      sections: [],
      busySending: false,
      totalFormScore: 0,
    };
  },
  methods: {
    inputChange(field, event) {
      field.userData = [];
      field.userData.push(event.value);
    },
    setupClientForm() {
      this.clientForm = this.form;

      for (let i = 0; i < this.clientForm.sections.length; i++) {
        const section = this.clientForm.sections[i];

        section.client_response = JSON.parse(section.client_response);
        this.sections.push(section);
      }

      this.sections.forEach((s) => {
        s.client_response.forEach((field) => {
          field.userData = [];
        });
      });
    },
    sendResponse() {
      var confirmed = confirm(
        "Your response will be sent to your practitioner. You will not be able to modify your response after submission. Continue?"
      );
      if (confirmed) {
        this.busySending = true;
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "/api/front-end/forms/" +
              this.clientForm.id +
              "/complete",
            {
              clinic_id: this.clinicId,
              client_form_id: this.clientForm.id,
              follow_up_mail_id: parseInt(this.followUpMailId),
              email_series_id: parseInt(this.emailSeriesId),
            }
          )
          .then(({ data }) => {
            // Bus.$emit("alert", data);

            console.log("alert", data);

            this.clientForm.status = "response_received";
            this.clientForm.client_response_date = moment.now();
            this.busySending = false;
          });
      }
    },
    getNumberOfQs(section) {
      var count = 0;

      const options = JSON.parse(section.form_options);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].type !== "header" && options[i].type !== "paragraph") {
            count++;
          }
        }
      }
      return count;
    },
  },
  computed: {
    formHasScoredSections() {
      let hasScored = false;
      this.sections.forEach((item) => {
        if (item.scored) {
          hasScored = true;
        }
      });
      return hasScored;
    }
  },
  mounted() {
    this.setupClientForm();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  components: {
    FormSection,
    Score,
  },
};
</script>

<style>
</style>